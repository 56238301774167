@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-color: #000000;
  background-image: url('./img/oficina.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center bottom; */
  background: linear-gradient(90deg, #071620 0%, rgba(3, 10, 15, 0.81) 53.12%, rgba(0, 0, 0, 0.3) 100%), url('./img/oficina.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-attachment: fixed;
  color: #FFFFFF;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* p{
  font-size: 1rem;
} */

.gradiente{
  background: linear-gradient(to right, blue, white);
  width: 100%;
}
